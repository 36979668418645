'use client'

import { NotificationBadge } from '@/components/NotificationBadge'
import { useFavoritesStore } from '@/lib/store/favorite'
import { useSeenStore } from '@/lib/store/seen'
import { useEffect, useState } from 'react'

export function HeaderFavorites() {
  const [count, setCount] = useState(0)
  const { favoriteMe, listNotViewed } = useFavoritesStore((state) => ({ favoriteMe: state.usersMe, listNotViewed: state.listNotViewed }))
  const { seen, listNotViewedSeen } = useSeenStore((state) => ({ seen: state.users, listNotViewedSeen: state.listNotViewed }))

  useEffect(() => {
    setCount(listNotViewed?.().length + listNotViewedSeen?.()?.length)
  }, [favoriteMe, seen])

  return (
    <NotificationBadge.Root>
      <NotificationBadge.Label>
        Favoritos
      </NotificationBadge.Label>
      <NotificationBadge.Badge count={count} limit={9} />
    </NotificationBadge.Root>
  )
}