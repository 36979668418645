import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderBannerBody"] */ "/vercel/path0/components/Banner/BannerBody.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderBannerRoot"] */ "/vercel/path0/components/Banner/BannerRoot.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderAvatar"] */ "/vercel/path0/components/HeaderV2/HeaderAvatar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderChat"] */ "/vercel/path0/components/HeaderV2/HeaderChat.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderFavorites"] */ "/vercel/path0/components/HeaderV2/HeaderFavorites.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/MenuList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Socket"] */ "/vercel/path0/components/Socket.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Firebase"] */ "/vercel/path0/components/Utils/Firebase.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BaselimeRum"] */ "/vercel/path0/node_modules/@baselime/react-rum/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/(lang)/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/inter.ttf\",\"weight\":\"400\"}],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/Providers/NextAuthProvider.tsx");
