"use client";

import React, { useState } from 'react'

import { ButtonSignOut } from '@/components/ButtonSignOut'
import { CiSettings, CiUser } from 'react-icons/ci'

import {
  Avatar,
  AvatarFallback,
  AvatarImage
} from '@/components/ui/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import Link from 'next/link'
import { Button } from '@/components/ui/button'
import { IUser } from '@/types/user'

export interface IHeaderAvatar {
  profile_picture: IUser['profile_picture']
  username: IUser['username']
}

export function HeaderAvatar({ profile_picture, username }: IHeaderAvatar) {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <DropdownMenu onOpenChange={setShowDropdown} open={showDropdown}>
      <DropdownMenuTrigger onClick={() => setShowDropdown(true)}>
        <Avatar className='border border-white size-11'>
          <AvatarImage src={profile_picture} alt={username} />
          <AvatarFallback>{username?.slice(0, 2)?.toUpperCase()}</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>

      <DropdownMenuContent className='flex flex-col gap-1'>
        <DropdownMenuItem onClick={() => setShowDropdown(false)}>
          <Link passHref href='/profile' className='w-full'>
            <Button
              className='flex justify-start gap-2 w-full text-white'
              variant='ghost'
            >
              <CiUser />
              <span>Perfil</span>
            </Button>
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setShowDropdown(false)}>
          <Link passHref href='/profile/configurations/notifications'>
            <Button
              className='flex justify-start gap-2 w-full text-white'
              variant='ghost'
            >
              <CiSettings />
              <span>Configurações</span>
            </Button>
          </Link>
        </DropdownMenuItem>

        <DropdownMenuItem onClick={() => setShowDropdown(false)}>
          <ButtonSignOut />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>

  )
}