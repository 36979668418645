import { create } from 'zustand'
import { persist } from 'zustand/middleware'
export type State = {
  usersId: number[]
  usersMe: { id: number, viewed: boolean }[]
}

export type Actions = {
  addUser: (userId: number) => void
  removeUser: (userId: number) => void
  removeUserMe: (userId: number) => void
  populate: (usersId: number[]) => void
  populateMe: (usersMe: { id: number, viewed: boolean }[]) => void
  listNotViewed: () => { id: number, viewed: boolean }[]
  setAllViewed: () => void
}

export const useFavoritesStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      usersId: [],
      usersMe: [],
      addUser: (userid: number) => set((state) => ({ usersId: [ ...state.usersId, userid ] })),
      removeUser: (userid: number) => set((state) => ({ usersId: state.usersId.filter((id) => id !== userid) })),
      removeUserMe: (userid: number) => set((state) => ({ usersMe: state.usersMe.filter((user) => user.id !== userid) })),
      populate: (usersId: number[]) => set({ usersId }),
      populateMe: (usersMe: { id: number, viewed: boolean }[]) => set({ usersMe }),
      listNotViewed: () => get().usersMe.filter((user) => !user.viewed),
      setAllViewed: () => set((state) => ({ usersMe: state.usersMe.map((user) => ({ ...user, viewed: true }) ) }))
    }),
    { name: 'my-favorites', skipHydration: true }
  )
)