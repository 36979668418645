import {
  Box, Button, Image, Link, Text
} from '@chakra-ui/react'
import { useState } from 'react'
import { _calculateAge } from '../utils/dates'

import { toast } from 'sonner'
import { useAuth } from '../contexts/auth'
import api from '../utils/api'
import { socket } from '../utils/socket'

export default function Notification({ user, message }): JSX.Element {
  const [ loadingRequest, setLoadingRequest ] = useState(false)
  const { user: loggedUser } = useAuth()

  const handle = async (id, status) => {
    setLoadingRequest(true)
    try {
      await api.patch('users/update-private-photos-request/' + id, { status })
      toast.success('Atualizado com sucesso!')

      const payload = {
        from: { id: loggedUser.id },
        to: { id: id },
        status
      }

      socket?.emit('change_to_see_my_private_pictures', payload)
      socket?.emit('list_request_private_pricture', payload)

    } catch (error) {
      setLoadingRequest(false)
      toast.error('Ocorreu um erro')
    } finally {
      setLoadingRequest(false)
    }
  }

  return (
    <>
      {
        (message === 'Visitou seu perfil')
          ? (
            <Link passHref href={'/profile/' + user.id} legacyBehavior>
              <Box key={user?.id} display="flex" justifyContent="space-between">
                <Image
                  borderRadius="20px"
                  border="3px solid transparent"
                  p="0"
                  objectFit="cover"
                  style={{
                    borderImageOutset: 1,
                    borderImageSlice: 1,
                    background:
                    'linear-gradient(86.95deg, #7E7E7E 5.19%, #E3E3E3 30.67%, #EDEDED 57.14%, #E3E3E3 81.64%, #525252 99.29%)'
                  }}
                  width="65px"
                  height="65px"
                  src={
                    user?.profile_picture || '/assets/avatar.svg'
                  }
                ></Image>

                <Box ml="2" width="100%">
                  <Text display="flex" fontSize="2xl" color="black">
                    {user?.username + ', ' + _calculateAge(new Date(user?.birthdate))}
                  </Text>
                  <Text>{message}</Text>
                </Box>
              </Box>
            </Link>
          )
          : (message === 'Enviou uma mensagem')
            ? (
              <Link passHref href={'/chat'} legacyBehavior>
                <Box key={user?.id} display="flex" justifyContent="space-between">
                  <Image
                    borderRadius="20px"
                    border="3px solid transparent"
                    p="0"
                    objectFit="cover"
                    style={{
                      borderImageOutset: 1,
                      borderImageSlice: 1,
                      background:
                    'linear-gradient(86.95deg, #7E7E7E 5.19%, #E3E3E3 30.67%, #EDEDED 57.14%, #E3E3E3 81.64%, #525252 99.29%)'
                    }}
                    width="65px"
                    height="65px"
                    src={
                      user?.profile_picture || '/assets/avatar.svg'
                    }
                  ></Image>

                  <Box ml="2" width="100%">
                    <Text display="flex" fontSize="2xl" color="black">
                      {user?.username + ', ' + _calculateAge(new Date(user?.birthdate))}
                    </Text>
                    <Text>{message}</Text>
                  </Box>
                </Box>
              </Link>
            )
            : (message === 'Te favoritou')
              ? (
                <Link passHref href={'/favorites'} legacyBehavior>
                  <Box key={user?.id} display="flex" justifyContent="space-between">
                    <Image
                      borderRadius="20px"
                      border="3px solid transparent"
                      p="0"
                      objectFit="cover"
                      style={{
                        borderImageOutset: 1,
                        borderImageSlice: 1,
                        background:
                    'linear-gradient(86.95deg, #7E7E7E 5.19%, #E3E3E3 30.67%, #EDEDED 57.14%, #E3E3E3 81.64%, #525252 99.29%)'
                      }}
                      width="65px"
                      height="65px"
                      src={
                        user?.profile_picture || '/assets/avatar.svg'
                      }
                    ></Image>

                    <Box ml="2" width="100%">
                      <Text display="flex" fontSize="2xl" color="black">
                        {user?.username + ', ' + _calculateAge(new Date(user?.birthdate))}
                      </Text>
                      <Text>{message}</Text>
                    </Box>
                  </Box>
                </Link>
              )
              : (message === 'Quer ver suas fotos privadas')
                ? (
                  <Box display="flex" flexDirection="column">
                    <Box key={user?.id} display="flex" justifyContent="space-between">
                      <Image
                        borderRadius="20px"
                        border="3px solid transparent"
                        p="0"
                        objectFit="cover"
                        style={{
                          borderImageOutset: 1,
                          borderImageSlice: 1,
                          background:
                    'linear-gradient(86.95deg, #7E7E7E 5.19%, #E3E3E3 30.67%, #EDEDED 57.14%, #E3E3E3 81.64%, #525252 99.29%)'
                        }}
                        width="65px"
                        height="65px"
                        src={
                          user?.profile_picture || '/assets/avatar.svg'
                        }
                      ></Image>

                      <Box ml="2" width="100%">
                        <Text display="flex" fontSize="2xl" color="black">
                          {user?.username + ', ' + _calculateAge(new Date(user?.birthdate))}
                        </Text>
                        <Text>{message}</Text>
                      </Box>
                    </Box>
                    <Box>
                      <Button
                        width="85px"
                        bgColor="green"
                        color="white"
                        _hover={{
                          bg: 'green.600',
                          color: 'white'
                        }}
                        _active={{
                          bg: 'green.600',
                          color: 'white'
                        }}
                        onClick={() => handle(user?.id, 'APPROVED')}
                        m="1"
                      >
                        <Text fontSize="14px">
                  Aceitar
                        </Text>
                      </Button>

                      <Button
                        width="85px"
                        bgColor="red"
                        color="white"
                        _hover={{
                          bg: 'red.600',
                          color: 'white'
                        }}
                        _active={{
                          bg: 'red.600',
                          color: 'white'
                        }}
                        m="1"
                        onClick={() => handle(user?.id, 'REJECTED')}
                      >
                        <Text fontSize="14px">
                  Recusar
                        </Text>
                      </Button>
                    </Box>
                  </Box>
                )
                : (message === 'Aprovou o seu acesso as fotos privadas') && (
                  <Link passHref href={'/profile/' + user.id} legacyBehavior>
                    <Box display="flex" flexDirection="column">
                      <Box key={user?.id} display="flex" justifyContent="space-between">
                        <Image
                          borderRadius="20px"
                          border="3px solid transparent"
                          p="0"
                          objectFit="cover"
                          style={{
                            borderImageOutset: 1,
                            borderImageSlice: 1,
                            background:
                      'linear-gradient(86.95deg, #7E7E7E 5.19%, #E3E3E3 30.67%, #EDEDED 57.14%, #E3E3E3 81.64%, #525252 99.29%)'
                          }}
                          width="65px"
                          height="65px"
                          src={
                            user?.profile_picture || '/assets/avatar.svg'
                          }
                        ></Image>

                        <Box ml="2" width="100%">
                          <Text display="flex" fontSize="2xl" color="black">
                            {user?.username + ', ' + _calculateAge(new Date(user?.birthdate))}
                          </Text>
                          <Text>{message}</Text>
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                )
      }
    </>
  )
}