'use client'

import { NotificationBadge } from '@/components/NotificationBadge'
import { useChatStore } from '../../lib/store/chat'

export function HeaderChat() {
  const { unread } = useChatStore((state) => ({ unread: state.unread }))

  return (
    <NotificationBadge.Root>
      <NotificationBadge.Label>
        Conversas
      </NotificationBadge.Label>
      <NotificationBadge.Badge count={unread.length} limit={9} />
    </NotificationBadge.Root>
  )
}