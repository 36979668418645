import { create } from 'zustand'
import { persist } from 'zustand/middleware'
export type State = {
  users: { id: number, viewed: boolean }[]
}

export type Actions = {
  addUser: (userId: number) => void
  removeUser: (userId: number) => void
  populate: (users: { id: number, viewed: boolean }[]) => void
  listNotViewed: () => { id: number, viewed: boolean }[]
  setAllViewed: () => void

}

export const useSeenStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      users: [],
      addUser: (userid: number) => set((state) => ({ users: [ ...state.users, { id: userid, viewed: false } ] })),
      removeUser: (userid: number) => set((state) => ({ users: state.users.filter((user) => user.id !== userid) })),
      populate: (users: { id: number, viewed: boolean }[]) => set({ users }),
      listNotViewed: () => get().users.filter((user) => !user.viewed),
      setAllViewed: () => set((state) => ({ users: state.users.map((user) => ({ ...user, viewed: true }) ) }))
    }),
    { name: 'my-favorites', skipHydration: true }
  )
)