import { AxiosError } from 'axios'
import { format, parse } from 'date-fns'
import {
  signIn, signOut, useSession
} from 'next-auth/react'
import { useRouter } from 'next/navigation'
import { destroyCookie } from 'nookies'
import {
  createContext,
  useContext, useEffect,
  useState
} from 'react'
import { toast } from 'react-toastify'
import { IUser } from '../types/user'
import api from '../utils/api'
import { socket } from '../utils/socket'

const AuthContext = createContext<any>({})

export const AuthProvider = ({ children }: any): any => {
  const [user, setUser] = useState<IUser>(null)
  const [userTemp, setUserTemp] = useState(null)
  const [error, setError] = useState(null)
  const [signUpData, setSignUpData] = useState(null)
  const [loading, setLoading] = useState<any>(null)
  const [loadingAuth, setLoadingAuth] = useState<any>([])
  const [signUpSuccess, setSignUpSuccess] = useState(false)
  const router = useRouter()

  const [myFavorites, setMyFavirites] = useState<any>([])
  const [requestToSeeMyPrivatePictures, setRequestToSeeMyPrivatePictures] = useState<any>([])
  const [blockedUsers, setBlockedUsers] = useState<any>([])
  const [blockedByUsers, setBlockedByUsers] = useState<any>([])
  const [publicPictures, setPublicPictures] = useState<{ is_private: boolean, url: string }[]>([])

  const { data: session, update } = useSession()

  useEffect(() => {
    if (session?.user) {
      setUser({ ...session?.user })
    }

    if (session?.access_token) {
      socket.disconnect()
      socket.auth = { token: session?.access_token }
      socket.connect()
    }

    if (!user && session?.user) {
      getProfile()
      onUpdateFavoritedUsers()
      onUpdateBlockedUsers()
      onUpdateRequestToSeeMyPrivatePictures()
      onUpdateBlockedByUsers()
    }

  }, [session])

  useEffect(() => {
    socket?.on('change_in_blockers', () => {
      onUpdateBlockedByUsers()
      onUpdateBlockedUsers()
    })

    socket?.on('change_to_see_my_private_pictures', () => {
      onUpdateRequestToSeeMyPrivatePictures()
    })

    return () => {
      socket.off('change_in_blockers')
      socket.off('change_to_see_my_private_pictures')
    }

  }, [socket, session])

  useEffect(() => {
    socket?.on('reload_profile', async () => {
      await getProfile()
    })

    socket?.on('handle_ban', async () => {
      toast.warn('Você foi banido da plataforma!')
      await logout()
    })

    return () => {
      socket.off('reload_profile')
      socket.off('handle_ban')
    }
  }, [socket, session, user])

  const getProfile = async () => {
    await api.get<IUser>('users/profile')
      .then(async ({ data }) => {
        await updateUserSession({ ...data })
      })
      .catch(async (error: AxiosError) => {
        if (error.status === 401 && !!session.user) {
          await logout()
        }
      })

  }

  const updateUserSession = async (data: Partial<IUser>): Promise<void> => {
    const newSession = {
      ...session,
      user: {
        ...session?.user,
        ...data
      }
    }

    await update(newSession)
  }

  const onUpdateFavoritedUsers = async (): Promise<void> => {
    try {
      const { data } = await api.get('users/favorites')
      setMyFavirites(data)
    } catch (error) {
      toast.error('Ocorreu um erro')
    }
  }

  const onUpdateBlockedUsers = async (): Promise<void> => {
    try {
      const { data } = await api.get(`users/block/${session.user.id}`)
      setBlockedUsers(data)
    } catch (error) {
      toast.error('Ocorreu um erro')
    }
  }

  const onUpdateBlockedByUsers = async (): Promise<void> => {
    try {
      const { data } = await api.get('users/blocked-by')
      setBlockedByUsers(data)
    } catch (error) {
      toast.error('Ocorreu um erro')
    }
  }

  const onUpdateRequestToSeeMyPrivatePictures = async (): Promise<void> => {
    try {
      const [
        { data: recived },
        { data: send }
      ] = await Promise.all([
        api.get('users/private-photos-recived'),
        api.get('users/private-photos-send')
      ])
      setRequestToSeeMyPrivatePictures([...recived, ...send])
    } catch (error) {
      toast.error('Ocorreu um erro')
    }
  }

  useEffect(() => {
    setLoadingAuth(false)
  }, [])

  const forgotPassword = async (email): Promise<any> => {
    try {
      await api.put('auth/forgot-password', { email })
    } catch (error) {
      setError('Erro, e-mail não cadastrado')
    }
  }

  const checkEmailUsernameExists = async (email, username): Promise<any> => {
    try {
      const { data } = await api.post('auth/check-exists', {
        email,
        username
      })
      return data
    } catch (error) {
      setError('Usuário ou senha incorretos, verifique os dados')
    }
  }

  const favorite =
    async (favoritedUserId): Promise<any> => {
      setLoading(favoritedUserId)
      try {
        await api.post('users/favorite', {
          userId: user?.id,
          favoritedUserId
        })
        await onUpdateFavoritedUsers()
        emitterOnFavorite(favoritedUserId)
        toast.success('Favoritado com sucesso!')

      } catch (error) {
        setLoading(null)
        toast.error('Ocorreu um erro')
      } finally {
        setLoading(null)
      }
    }

  const unfavorite = async (id): Promise<any> => {
    setLoading(id)
    try {
      await api.delete('users/unfavorite/' + id)
      await onUpdateFavoritedUsers()
      toast.success('Desfavoritado com sucesso!')

    } catch (error) {
      setLoading(null)
      toast.error('Ocorreu um erro')
    } finally {
      setLoading(null)
      setLoading(false)
    }
  }

  const emitterHandleBlock = (toUserId: string | number) => {
    const payload = {
      from: { id: user.id },
      to: { id: toUserId }
    }

    socket?.emit('change_in_blockers', payload)
  }

  const emitterOnViewProfile = (toUserId: string | number) => {
    const paylod = {
      from: { id: user?.id },
      to: { id: toUserId }
    }

    // socket?.emit('on_view_profile', paylod)
  }

  const emitterOnFavorite = (toUserId: string | number) => {
    const paylod = {
      from: { id: user?.id },
      to: { id: toUserId }
    }

    socket?.emit('on_favorited', paylod)
  }

  const block =
    async (blockedId): Promise<any> => {
      setLoading(blockedId)
      try {
        await api.post('users/block', {
          userId: user?.id,
          blockedId
        })
        emitterHandleBlock(blockedId)
        await onUpdateBlockedUsers()
        toast.success('Bloqueado com sucesso!')

      } catch (error) {
        setLoading(null)
        toast.error('Ocorreu um erro')
      } finally {
        setLoading(null)
      }
    }

  const unblock = async (id): Promise<any> => {
    // setLoading(unblock)
    try {
      const { data } = await api.delete<{ blockedUser?: { id: number } }>('users/unblock/' + id)
      emitterHandleBlock(data.blockedUser.id)
      await onUpdateBlockedUsers()
      toast.success('Desbloqueado com sucesso!')
    } catch (error) {
      // setLoading(null)
      toast.error('Ocorreu um erro')
    }
    // finally {
    //   setLoading(null)
    // }
  }

  const denounce = async (blockedId): Promise<any> => {
    try {
      await api.post('users/denounce', {
        userId: user?.id,
        blockedId
      })
      toast.success('Usuário denunciado!')
    } catch (error) {
      setLoading(null)
      toast.error('Ocorreu um erro')
    } finally {
      setLoading(null)
    }
  }

  const deletePicture = async (id): Promise<any> => {
    setLoading(true)
    try {
      await api.delete('users/pictures/' + id)
      toast.success('Removido com sucesso!')

    } catch (error) {
      toast.error('Ocorreu um erro')
    } finally {
      setLoading(false)
    }
  }

  const signUp = async (data): Promise<any> => {
    const { photos, private_pictures, profile_picture, ...rest } = data
    const { fromInitiative } = router.query

    const date = parse(rest.birthdate, 'dd/MM/yyyy', new Date())
    rest.birthdate = format(new Date(date), 'yyyy-MM-dd')

    if (fromInitiative) {
      rest.fromInitiative = fromInitiative
    }

    try {
      const promisses: any = []
      const res = await api.post('auth/register', rest)
      if (res) {

        if (photos) {
          for (const photo of photos) {
            const form_data_pictures = new FormData()
            form_data_pictures.append('register', 'true')
            form_data_pictures.set('pictures', photo)
            promisses.push(api.post('users/' + res.data.id + '/pictures', form_data_pictures, { headers: { Authorization: `Bearer ${res.data.access_token}` } }))
          }

        }
        if (private_pictures) {
          for (const private_picture of private_pictures) {
            const form_data_private_pictures = new FormData()
            form_data_private_pictures.append('register', 'true')
            form_data_private_pictures.set('private_pictures', private_picture)
            promisses.push(api.post('users/' + res.data.id + '/pictures', form_data_private_pictures, { headers: { Authorization: `Bearer ${res.data.access_token}` } }))
          }
        }

        if (profile_picture) {
          const form_data = new FormData()
          form_data.append('profile_picture', profile_picture)
          form_data.set('register', 'true')
          promisses.push(api.post('users/' + res.data.id + '/pictures', form_data, { headers: { Authorization: `Bearer ${res.data.access_token}` } }))
        }

        await Promise.allSettled(promisses)

        await signIn('credentials', {
          email: data.email,
          password: data.password,
          callbackUrl: '/profile'
        })

        setSignUpSuccess(true)
      }
    } catch (error) {
      toast.error('Ocorreu um erro')
    }
  }

  const edit = async (data): Promise<any> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { profile_picture, photos, ...rest } = data
    const form_data = new FormData()
    form_data.append('profile_picture', profile_picture)

    if (/^\d{4}-\d{2}-\d{2}$/.test(rest.birthdate)) {
      rest.birthdate = rest.birthdate.replace(/-/g, '/');

      const [year, month, day] = rest.birthdate.split('/');

      rest.birthdate = `${day}/${month}/${year}`;
    }

    const date = parse(rest.birthdate, 'dd/MM/yyyy', new Date())
    rest.birthdate = format(new Date(date), 'yyyy-MM-dd')

    try {
      data.birthdate = data.birthdate.split('/').reverse().join('-')
    } catch (error) {
      toast.error('Ocorreu um erro')
    }

    try {
      // await api.post('auth/update', rest)
      const [
        responseUser
      ] = await Promise.all([
        api.patch<IUser>('users', rest),
        api.post('users/' + data.id + '/pictures', form_data)
      ])

      await updateUserSession(responseUser.data)

      await router.push('/profile')
    } catch (error) {
      toast.error('Ocorreu um erro')
    }
  }

  const addPictures =
    async (data): Promise<any> => {
      const form_data = new FormData()

      form_data.append('pictures', data['pictures'])

      form_data.append('private_pictures', data['private_pictures'])

      form_data.append('profile_picture', data['profile_picture'])

      try {
        await api.post('users/' + user?.id + '/pictures', form_data)

      } catch (error) {
        toast.error('Ocorreu um erro')
      }
    }

  const verifyPicture =
    async (data): Promise<any> => {
      const form_data = new FormData()
      form_data.append('verification_picture', data['verification_picture'])

      if (data.type === 'private') {
        form_data.append('private_pictures', data['verification_picture'])
      } else if (data.type === 'public') {
        form_data.append('pictures', data['verification_picture'])
      }

      try {
        const { data } = await api.post('users/' + user?.id + '/pictures', form_data)
        await updateUserSession({ verification_picture: data.verification_picture })

      } catch (error) {
        toast.error('Ocorreu um erro')
      }
    }

  const logout = async (): Promise<void> => {
    destroyCookie(null, 'SEARCH_PREFERENCES')
    await signOut({ callbackUrl: '/login' })
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        userTemp,
        loading,
        logout,
        signUp,
        setUser,
        favorite,
        denounce,
        block,
        unfavorite,
        unblock,
        signUpData,
        setSignUpData,
        addPictures,
        signUpSuccess,
        setSignUpSuccess,
        error,
        edit,
        deletePicture,
        checkEmailUsernameExists,
        verifyPicture,
        loadingAuth,
        forgotPassword,
        updateUserSession,
        myFavorites,
        blockedUsers,
        requestToSeeMyPrivatePictures,
        blockedByUsers,
        emitterOnViewProfile,
        onUpdateRequestToSeeMyPrivatePictures,
        publicPictures
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): any => useContext(AuthContext)