import { clientApi } from '@/lib/client-api'

export async function listSeens() {
  const { data: users } = await clientApi<{ id: string, viewed: boolean }[]>({ url: 'seen' })

  return users
}

export async function viewedSeen() {
  await clientApi<number[]>({
    url: 'seen/viewed',
    method: 'POST'
  })
}