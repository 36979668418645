"use client";

import { createContext, useContext, useState } from 'react';
// import Cookies from 'js-cookie'


import api from '../../utils/api';

import { IUser } from '../../types/user';

import { AxiosError } from 'axios';
import { useSession } from "next-auth/react";
import { toast } from 'sonner';

interface IError {
  id: string
  message: string
  parameter: string
}

interface IErrorsResponse {
  errors: IError[]
}

export const FinanceContext = createContext<any>({})

export const FinanceProvider = ({ children }: any): any => {
  const [loading] = useState(true);

  const { data: session, update } = useSession();

  const user = session?.user;

  const updateUserSession = async (data: Partial<IUser>): Promise<void> => {
    const newSession = {
      ...session,
      user: {
        ...session?.user,
        ...data
      }
    }

    await update(newSession)
  }

  const onChoosePlan = async (plan, prices, period): Promise<any> => {
    try {
      localStorage.setItem('plan', JSON.stringify(plan));
      localStorage.setItem('period', JSON.stringify(period));
      localStorage.setItem('prices', JSON.stringify(prices));

      window.location.href = "/payment-plan";
    } catch (error) {
      toast.error('Erro ao abrir o plano');
    }
  }

  const handleBuy = async (data): Promise<any> => {
    const period = JSON.parse(localStorage.getItem('period') || "");
    const prices = JSON.parse(localStorage.getItem('prices') || "");

    const form_data = {
      card_number: data.number,
      holder_name: data.name,
      card_expiration: data.date,
      card_cvv: data.cvv,
      cpf: data.cpf,
      voucher: data.voucher,
      payment_method_code: "credit_card",
      payment_company_code: "mastercard",
      vindi_plan_id: prices[period].id,
    }

    if (user.payment_status !== 'PAID') {
      await updateUserSession({
        payment_status: 'PENDING',
      })
    }

    await api.post<Partial<IUser>>('auth/signature', form_data)
      .then(async ({ data }) => {

        toast.info('Assinatura criada, aguarde o pagamento e fique de olho no seu email.')
        window.location.href = "/profile"
      })
      .catch((error: AxiosError<IErrorsResponse>) => {
        if (error?.response?.data?.errors) {
          const { errors } = error?.response?.data

          errors
            .filter((e, index, self) => self.findIndex((i) => i.parameter === e.parameter) === index)
            .map((e) => {
              if (e.parameter === 'registry_code') {
                toast.error('CPF inálido')
              } else if (e.parameter === 'invalid_card') {
                toast.error('Cartão inválido')
              } else if (e.parameter === 'card_expiration') {
                toast.error('Data expiração inválida')
              } else if (e.parameter === 'holder_name') {
                toast.error('Nome do titular é obrigatório')
              }
            })
        }
      })
  }

  return (
    <FinanceContext.Provider
      value={{
        loading,
        onChoosePlan,
        handleBuy,
      }}
    >
      {children}
    </FinanceContext.Provider>
  )
}

export const useFinance = (): any => useContext(FinanceContext)
