'use client';

import React, { ReactNode } from 'react'

import { SessionProvider } from 'next-auth/react';
import { FinanceProvider } from '../app/contexts/finance';

import ControlSessionProvider from './ControlSessionProvider';

import { AuthProvider } from '../contexts/auth'

export default function NextAuthProvider({ children }: { children: ReactNode }) {
  return (
    <SessionProvider>
      <ControlSessionProvider>
        <AuthProvider>
          <FinanceProvider>
            {children}
          </FinanceProvider>
        </AuthProvider>
      </ControlSessionProvider>
    </SessionProvider>
  )
}