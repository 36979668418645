export function NotificationBadgeBadge({ count, limit }: { count: number, limit: number }) {

  return (
    <>
    {
      count > 0 &&
      <div className='absolute flex items-center justify-center bg-red p-1 size-5 rounded-full text-[14px] top-[-15px] right-[-10px]'>
        <p>
          {
            count > limit
              ? <>9+</>
              : count
          }
        </p>
      </div>
    }
  </>
  )
}