'use client'

import Notification from '@/components/Notification'
import { listBlockedMe, listMyBlocked } from '@/lib/block'
import { listFavoritesMe, listMyFavorites } from '@/lib/favorite'
import { listSeens } from '@/lib/seen'
import { useBlockedStore } from '@/lib/store/block'
import { useFavoritesStore } from '@/lib/store/favorite'
import { useSeenStore } from '@/lib/store/seen'
import { useSocketStore } from '@/lib/store/socketStore'
import { useUserPreferencesStore } from '@/lib/store/userPreferences'
import { socket } from '@/utils/socket'
import { useSession } from 'next-auth/react'
import { useEffect } from 'react'
import { toast } from 'sonner'
import useSound from 'use-sound'
import { listMyRequestPrivatePicture, listRequestPrivatePictureMe } from '../lib/private-pictures'
import { useChatStore } from '../lib/store/chat'
import { usePrivatePictureStore } from '../lib/store/private-picture'
import { SocketPayload } from '../types/socket'
import { IUser } from '../types/user'
import { NotificationsRoot } from './Notifications/Root'
import { INotification } from './Notifications/types'

export function Socket() {
  const { data: session } = useSession()
  const sound = useUserPreferencesStore((state) => state.sound)
  const [ play ] = useSound('/assets/sound.mp3', { volume: 0.25, soundEnabled: sound })
  const populate = useFavoritesStore((state) => state.populate)
  const populateMe = useFavoritesStore((state) => state.populateMe)
  const populateSeen = useSeenStore((state) => state.populate)
  const populateBlockedMy = useBlockedStore((state) => state.populateMy)
  const populateBlockedMe = useBlockedStore((state) => state.populateBlockedMe)
  const populatePrivateMy = usePrivatePictureStore((state) => state.populateMy)
  const populatePrivateMe = usePrivatePictureStore((state) => state.populatePrivateMe)
  const socketConnect = useSocketStore((state) => state.connect)
  const socketChat = useSocketStore((state) => state.socket)
  const { changeUnread } = useChatStore((state) => ({ changeUnread: state.changeUnread }))

  useUserPreferencesStore.persist?.rehydrate()

  useEffect(() => {
    const receiveNotification = (data) => {
      if (data.message === 'Visitou seu perfil') {
        listSeens().then((usersId) => populateSeen(usersId ?? []))
      } else if (data.message === 'Te favoritou') {
        listFavoritesMe().then((usersId) => populateMe(usersId ?? []))
      }
      toast(<Notification user={data.from} message={data.message} />, {})
      play()
    }

    if (socket.connected) {
      socket?.on('receive_notification', receiveNotification)
    }

    return () => {
      socket?.off('receive_notification', receiveNotification)
    }
  }, [ play, socket.connected ])

  useEffect(() => {
    const changeOnBlock = () => {
      listMyBlocked().then((usersId) => populateBlockedMy(usersId ?? []))
      listBlockedMe().then((usersId) => populateBlockedMe(usersId ?? []))
    }

    const changeOnPrivatePictures = () => {
      listMyRequestPrivatePicture().then((usersId) => populatePrivateMy(usersId ?? []))
      listRequestPrivatePictureMe().then((usersId) => populatePrivateMe(usersId ?? []))
    }

    const onReceiveREquestPrivateAccessPicture = (data: {id: number, from: IUser, to: IUser, status: string}[]) => {
      const treated = data.map((i) => ({
        id: i.id, status: i.status, from: { id: i.from?.id }, to: { id: i.to?.id }
      }))
      populatePrivateMe(treated ?? [])
    }

    socket.on('response_private_pictures', onReceiveREquestPrivateAccessPicture)

    socket?.on('change_in_blockers', changeOnBlock)
    socket?.on('change_to_see_my_private_pictures', changeOnPrivatePictures)

    return () => {
      socket.off('change_in_blockers', changeOnBlock)
      socket?.off('change_to_see_my_private_pictures', changeOnPrivatePictures)
      socket?.off('response_private_pictures', onReceiveREquestPrivateAccessPicture)

    }

  }, [ socket.connected ])

  useEffect(() => {
    const onNotification = (payload: SocketPayload<INotification>) => {
      if (session?.user.id !== Number(payload.data.from.userId)) {
        toast(<NotificationsRoot {...payload.data} room={payload.room} />, {})
      }
    }

    const onQuantityUnreadMessage = (payload: SocketPayload<{ id: string }[]>) => {
      changeUnread(payload.data)
    }

    const emitListUnreadMessage = () => socketChat?.emit('quantityUnreadMessage')

    socketChat?.on('onNotification', onNotification)
    socketChat?.on('onQuantityUnreadMessage', onQuantityUnreadMessage)
    socketChat?.on('message', emitListUnreadMessage)
    socketChat?.on('listenRead', emitListUnreadMessage)
    socketChat?.on('listenUnread', emitListUnreadMessage)

    return () => {
      socketChat?.off('onNotification', onNotification)
      socketChat?.off('onQuantityUnreadMessage', onQuantityUnreadMessage)
      socketChat?.off('message', emitListUnreadMessage)
      socketChat?.off('listenRead', emitListUnreadMessage)
      socketChat?.off('listenUnread', emitListUnreadMessage)
    }
  }, [ socketChat, session?.user ])

  useEffect(() => {
    if (!socket.connected && session?.user) {
      socket.auth = { token: session?.access_token }
      socket.connect()
    }
  }, [ session ])

  useEffect(() => {
    socketConnect()
  }, [ socketConnect ])

  useEffect(() => {
    if (session?.user) {
      listMyFavorites().then((usersId) => populate(usersId ?? []))
      listFavoritesMe().then((usersId) => populateMe(usersId ?? []))
      listSeens().then((usersId) => populateSeen(usersId ?? []))
      listMyBlocked().then((usersId) => populateBlockedMy(usersId ?? []))
      listBlockedMe().then((usersId) => populateBlockedMe(usersId ?? []))
      listMyRequestPrivatePicture().then((usersId) => populatePrivateMy(usersId ?? []))
      listRequestPrivatePictureMe().then((usersId) => populatePrivateMe(usersId ?? []))
    }
  }, [ session ])

  return <></>
}